@plugins = do ($ = jQuery) ->
  $('.create-transaction-page input[name="username"]').autocomplete({
    source: "/plugin/names/",
    minLength: 1,
    select: ( event, ui ) ->
      $('input[name="account"]').val(ui.item.id)
      $('input[name="first-name"]').val(ui.item.realname)
      $('input[name="last-name"]').val(ui.item.lastname)
      $('input[name="id_number"]').val(ui.item.id_number)
  })

  $('.create-transaction-page input[name="username"]').change( (event) ->
    username = $(this).val()
    account = $('input[name="account"]').val()
    if username.length > 0 and account.length == 0
      $.ajax('/plugin/names', {
        data: {term: username },
      }).done((data) ->
        if data.length > 0 and data[0].value.toUpperCase() == username.toUpperCase()
          $('input[name="account"]').val(data[0].id)
          $('input[name="first-name"]').val(data[0].realname)
          $('input[name="last-name"]').val(data[0].lastname)
          $('input[name="id_number"]').val(data[0].id_number)
      )
  )

  $('.send-message-page input[name="username"]').autocomplete({
    source: "/plugin/names/",
    minLength: 1,
    select: ( event, ui ) ->
      $('input[name="account"]').val(ui.item.id)
      $('input[name="first-name"]').val(ui.item.realname)
      $('input[name="last-name"]').val(ui.item.lastname)
      $('input[name="id_number"]').val(ui.item.id_number)
  })

  $('.send-message-page input[name="username"]').change( (event) ->
    username = $(this).val()
    if username.length > 0
      $.ajax('/plugin/names', {
        data: {term: username },
      });
  )
  
